<template>
<div>
    <Wrapper>
        <Sidebar slot="sidebar" v-show="false"/>
        <Navbar slot="navbar" v-show="false" />

        <div slot="content">
            <div class="container">
                <div class="about-div">


                     <div class="text-center pb-4" >
                            <img src="../../../assets/images/logo2.png" height="80">
                        </div>
                             <div class="div-container text-center pt-4">
                          <button class="drift-open-chat " style="background:#D8F2D5; color:#50A866; min-height:40px; border-radius:5px; border:1px solid #D8F2D5;">Chat Us</button>
                  

                    </div>
                         
                    
                </div>
            </div>
        </div>
    </Wrapper>
</div>
</template>

<script>
import Navbar from '../../partials/Navbar'
import Sidebar from '../../partials/Sidebar'
import Footer from '../../partials/Footer'
import Wrapper from '../../partials/Wrapper'
import trans from "../../../../translations";
import searchProduct from "../SearchProduct";
import http from "../../../http/index";
import {
    commonJs
} from '../mixins/commonJs.js';

import {
    mapState
} from "vuex";

export default {
    mixins: [commonJs],
    data() {

        return {

        }
    },

    watch: {

    },
    methods: {

    },
    components: {
        Navbar,
        Footer,
        Sidebar,
        Wrapper,
        searchProduct
    },

   
    mounted() {
       
          localStorage.setItem("token", 'app user');
          let driftUserDetails = {
            email: 'emailMan@lol.co',
            nickName: 'noNickname'
          };

          localStorage.setItem(
            "driftUserDetails",
            JSON.stringify(driftUserDetails)
          );
    }

   
}
</script>

<style scoped>

</style>
