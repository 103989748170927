import { render, staticRenderFns } from "./DriftHelp.vue?vue&type=template&id=79f89b1e&scoped=true&"
import script from "./DriftHelp.vue?vue&type=script&lang=js&"
export * from "./DriftHelp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79f89b1e",
  null
  
)

export default component.exports