<template>
<div>
 <DriftHelp/>
</div>
</template>

<script>
import DriftHelp from '../../components/dashboard/helpSupport/DriftHelp.vue'
export default {
   
    components: {
           DriftHelp
       },
methods:{
},

   mounted(){

   }
}
</script>

<style>
    